$(document).ready(function() {

  if (getURLParameter('mdshare') != null) {
    $('.download-area').show();
  } else {
    $('.copyright-container').css('margin-bottom', 0);
  }

  $(window).scroll(function() {
    if ($(window).scrollTop() > $('.article-content-container').offset().top) {
      $('.author-container').fadeIn();
    } else {
      $('.author-container').fadeOut();
    }
  });

  // var urlHeader = 'http://test.article.meng-dong.com/access';
  var urlHeader = '/access';
  var articleId = getURLParameter('a');
  var requestData = {
    cmd: 2013,
    request: {
      articleId: articleId
    }
  };
  $.ajax({
    type: "POST",
    url: urlHeader,
    dataType: 'json',
    data: JSON.stringify(requestData),
    success: function(data) {
      if (data.ret == 0) {
        var responseData = $.parseJSON(data.response);
        if (responseData.code.code == 0) {}
        showArticle(responseData.articleList[0]);
      }
    },
    error: function() {
      console.log('error');
    }
  });

  function showArticle(article) {
    console.log(article);
    $('title').text(article.articleTitle);
    $('.author-name, .landing-author-name, #copyright-author-name').text(article.author.authorName);
    $('.author-title, .landing-author-title').text(article.author.authorTitle);
    $('.avatar-img').attr('src', article.author.headImgUrl);
    $('.landing-img').attr('src', article.landingImgUrl);
    var segList = article.articleSegmentList;
    for (var i = 0; i < segList.length; i++) {
      if (segList[i].segmentType == "IMAGE") {
        var imageItem = "<div class='img-container'><img class='article-content-img' src='11.png' data-original='" + segList[i].segmentContent + "'></div>";
        $('.article-content-container').append(imageItem);
      } else if (segList[i].segmentType == "PLAIN_TEXT") {
        var textItem = "<div class='text-container'><p class='text-content'>" + segList[i].segmentContent + "</p></div>";
        $('.article-content-container').append(textItem);
      } else if (segList[i].segmentType == "BOLD_TEXT") {
        var boldItem = "<div class='bold-container'><p class='bold-title-icon'></p><p class='bold-title-content'>" + segList[i].segmentContent + "</p></div>";
        $('.article-content-container').append(boldItem);
      }
    }
    $('.article-content-img').lazyload();
  }

  function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null
  }
});
